/**
 * `404` typically displays a 404 screen for paths that don't match one of the
 * pages built by Gatsby. However, we also use this component to render previews
 * of repeatable content type documents that have never been published. Unpublished
 * documents aren't yet processed by Gatsby so they get routed to this 404 template whenever
 * a content admin tries to preview them. To resolve this, we're checking to see
 * if a preview query param exists on the url, and if so load the appropriate template.
 */

import React, { useEffect, useState } from "react";
import { useAppState } from "@state";
import PageContainer from "@templates/DefaultPageContainer";
import { Container, Text, Button, ButtonAlt, Icon } from "@atoms";
import loadable from "@loadable/component";
import use404Data from "@staticQueries/Error404Query.js";

const Preview = loadable(() => import("@pageTemplates/Preview"));
const EopCta = loadable(() => import("@organisms/EopCta"));

const NotFoundPage = ({ location, ...rest }) => {
  const [, dispatch] = useAppState();
  const { heading, descriptor, button, secondaryLink } = use404Data();
  // eslint-disable-next-line no-unused-vars
  const [loaded, setLoaded] = useState(false);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    dispatch({ type: "setTheme", theme: "darkerTeal" });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isPreview = !!(
      urlParams.get("x-craft-live-preview") || urlParams.get("x-craft-preview")
    );

    if (isPreview) {
      setPreview(true);
    } else {
      setLoaded(true);
    }
  }, []);

  if (preview) {
    return <Preview location={location} {...rest} />;
  }

  // if (process.env.GATSBY_IN_DEV) {
  //   return (
  //     <DefaultPageContainer flex>
  //       <Container className="flex flex-col items-center justify-center">
  //         <Text variant="h2" className="mb-4">
  //           The site is still in progress.
  //         </Text>
  //         <Text variant="lg">
  //           Don&apos;t worry. Chances are the page you are looking for is not
  //           done yet.
  //         </Text>
  //       </Container>
  //     </DefaultPageContainer>
  //   );
  // }

  return (
    <PageContainer overflow>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"
        rel="stylesheet"
      />

      {/* typekit */}
      <link
        rel="stylesheet"
        href={`https://use.typekit.net/${process.env.GATSBY_TYPEKIT}.css`}
      />
      <section className="relative z-20 flex flex-col justify-center bg-black pb-10 pt-20 sm:pt-[7.5rem]">
        <Container variant="xs" className="relative">
          <div className="relative z-10 flex flex-col items-center justify-center gap-10">
            <div className="flex flex-col gap-5 text-center text-white">
              <Text variant="h2" className="mb-4">
                {heading}
              </Text>
              <Text variant="lg" className="whitespace-pre-line">
                {descriptor}
              </Text>
            </div>
            <div className="flex flex-wrap gap-5">
              {button?.url && <Button to={button.url}>{button.text}</Button>}
              {secondaryLink?.url && (
                <ButtonAlt to={secondaryLink.url} color="black">
                  {secondaryLink.text}
                </ButtonAlt>
              )}
            </div>
          </div>
          <div className="pointer-events-none absolute -right-[12.5%] top-40 w-full max-w-[12rem] -rotate-[11.5deg] sm:top-20 sm:max-w-[16rem] lg:-right-[25%]">
            <Icon
              name="logoIcon"
              className="w-full text-teal-dark opacity-50"
            />
          </div>
        </Container>
      </section>
      <EopCta bgColor="black" />
    </PageContainer>
  );
};

export default NotFoundPage;
