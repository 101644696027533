import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";

const query = graphql`
  query Error404Query {
    craft {
      globalSet(handle: "error404") {
        ... on Craft_error404_GlobalSet {
          # hero
          heading0
          descriptor0
          button: link0 {
            url
            text
          }
          secondaryLink: link1 {
            url
            text
          }
          image: image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => {
  const { heading0, descriptor0, button, secondaryLink, image0 } = globalSet;
  return {
    heading: heading0,
    descriptor: descriptor0,
    image: resolveImage(image0),
    button,
    secondaryLink,
  };
};

const useDefaultsData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useDefaultsData;
